<template>
  <section class="testimonials">

    <div class="mt-4 has-text-centered">
      <p class="subtitle-sm pt-3">Testimonials</p>
      <p class="title">Don't take our word for it</p>
    </div>
    <div v-if="Reviews.length" class="media--container order-1-mobile container-md">
      <div
        class="media-content mb-3"
        v-for="review in Reviews"
        :key="review.url"
      >
        <div
          class="content is-flex is-align-items-center is-flex-direction-column mt-4"
        >
          <p
            class="has-text-weight-semibold is-capitalized testimony-title mb-3"
          >
            {{ review.title }}
          </p>
          <p class="subtitle mb-5">{{ review.text }}</p>
          <b-rate class="block mb-3" v-model="review.rating" disabled></b-rate>
        </div>
      </div>
    </div>


    <div class="is-flex is-justify-content-space-between order-2-mobile" :class="{ 'mb-4': isMobileView }">
      <div class="rating-container pb-5" v-if="num_reviews && rating">
        <div class="is-flex is-justify-content-center">
          <img class="ta_logo mr-2" src="../../assets/tripadvisor-circle.svg" />
          <a
            :href="writeReviewDirectLink"
            target="_blank"
            class="outlined-button is-flex is-justify-content-center"
          >
            Write a review
          </a>
        </div>
      </div>

      <div class="is-flex is-justify-content-flex-end">
        <div class="mr-2 btn-outlined" @click="scroll_left">
          <b-icon icon="arrow-left"></b-icon>
        </div>
        <div class="btn-outlined" @click="scroll_right">
          <b-icon icon="arrow-right"></b-icon>
        </div>
      </div>
    </div>

   
    <div class="mb-6 mt-6 scroll ">
      <div class="m-scroll">
        <div class="about-image" v-for="image in randomImages" :key="image">
            <img :src="image" />
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import { dateTimeToMonthYear } from "../../helpers";
import { HelpersMixin } from "../../mixins";
export default {
  mixins: [HelpersMixin],
  data() {
    return {
      images: [
        require("../../assets/gallery/1.png"),
        require("../../assets/gallery/9.png"),
        require("../../assets/gallery/2.png"),
        require("../../assets/gallery/3.png"),
        require("../../assets/gallery/4.png"),
        require("../../assets/gallery/5.png"),
        require("../../assets/gallery/6.png"),
        require("../../assets/gallery/7.png"),
        require("../../assets/gallery/11.png"),
        require("../../assets/gallery/12.jpeg"),
        require("../../assets/gallery/13.jpeg"),
        require("../../assets/gallery/14.jpeg"),
        require("../../assets/gallery/15.jpeg"),
        require("../../assets/gallery/16.jpeg"),
        require("../../assets/gallery/17.jpeg"),
        require("../../assets/gallery/18.jpeg"),
        require("../../assets/gallery/19.jpeg"),
        require("../../assets/gallery/20.jpeg"),
        require("../../assets/gallery/21.jpeg"),
      ],
      windowWidth: window.innerWidth,
      dateTimeToMonthYear,
      rating: null,
      writeReviewDirectLink:
        "https://www.tripadvisor.com/UserReview-g189158-d26717876-Guiders_pt_Shared_tours_activities-Lisbon_Lisbon_District_Central_Portugal.html?m=66827",
      num_reviews: null,
      Review: [],
      reviewsToShow: 4,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  computed: {
    randomImages() {
      return this.getRandomNonRepeatingElements(this.images, 14)
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  created() {
    this.getReviews();
    this.getCompanyData();
    this.onResize();
  },
  methods: {
    getRandomNonRepeatingElements(array, count) {
      const shuffledArray = this.shuffleArray(array.slice()); // Make a copy of the array and shuffle it
      const result = [];

      for (let i = 0; i < count; i++) {
        result.push(shuffledArray[i]);
      }

      return result;
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
    scroll_left() {
      let content = document.querySelector(".media--container");

      content.scrollBy({
        top: 0,
        left: -360,
        behavior: "smooth",
      });
    },
    scroll_right() {
      let content = document.querySelector(".media--container");
      content.scrollBy({
        top: 0,
        left: +360,
        behavior: "smooth",
      });
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 768) {
        this.reviewsToShow = 1;
        return;
      }

      if (this.windowWidth <= 973) {
        this.reviewsToShow = 2;
        return;
      }
      if (this.windowWidth <= 1300) {
        this.reviewsToShow = 3;
        return;
      }
      this.reviewsToShow = 4;
    },
    getReviews() {
      /*   const headers = new Headers({
              "Origin": "www.guiders.pt",
              "Referer": "www.guiders.pt/",
          });
          const options = { method: 'GET', headers };
  
          fetch('https://api.content.tripadvisor.com/api/v1/location/26717876/reviews?language=en&key=7646BF694A494A348C8D5C67312479BF', options)
              .then(response => response.json())
              .then(response => {
                  this.rating = response.rating
                  this.num_reviews = response.num_reviews
              })
              .catch(err => console.error(err)); */
      const response = {
        location_id: "26717876",
        name: "Guiders.pt: Shared tours & activities",
        description:
          "We believe that the best trips are the ones we share with other adventure enthusiasts. We offer an exciting online platform that connects passionate travelers to unforgettable shared tours.",
        web_url:
          "https://www.tripadvisor.com/Attraction_Review-g189158-d26717876-Reviews-Guiders_pt_Shared_tours_activities-Lisbon_Lisbon_District_Central_Portugal.html?m=66827",
        address_obj: {
          street1: "Avenida Coronel Eduardo Galhardo",
          street2: "34 1dto",
          city: "Lisbon",
          country: "Portugal",
          postalcode: "1170-105",
          address_string:
            "Avenida Coronel Eduardo Galhardo 34 1dto, Lisbon 1170-105 Portugal",
        },
        ancestors: [
          {
            level: "City",
            name: "Lisbon",
            location_id: "189158",
          },
          {
            level: "District",
            name: "Lisbon District",
            location_id: "3874225",
          },
          {
            level: "Region",
            name: "Central Portugal",
            location_id: "3932272",
          },
          {
            level: "Country",
            name: "Portugal",
            location_id: "189100",
          },
        ],
        latitude: "0.0",
        longitude: "0.0",
        timezone: "Europe/Lisbon",
        email: "support@guiders.pt",
        phone: "+351 920 043 375",
        website: "https://guiders.pt",
        write_review:
          "https://www.tripadvisor.com/UserReview-g189158-d26717876-Guiders_pt_Shared_tours_activities-Lisbon_Lisbon_District_Central_Portugal.html?m=66827",
        ranking_data: {
          geo_location_id: "189158",
          ranking_string: "#84 of 679 Outdoor Activities in Lisbon",
          geo_location_name: "Lisbon",
          ranking_out_of: "679",
          ranking: "84",
        },
        rating: "5.0",
        rating_image_url:
          "https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/5.0-66827-5.svg",
        num_reviews: "41",
        review_rating_count: {
          1: "0",
          2: "0",
          3: "0",
          4: "0",
          5: "41",
        },
        photo_count: "34",
        see_all_photos:
          "https://www.tripadvisor.com/Attraction_Review-g189158-d26717876-m66827-Reviews-Guiders_pt_Shared_tours_activities-Lisbon_Lisbon_District_Central_Portugal.html#photos",
        hours: {
          periods: [
            {
              open: {
                day: 1,
                time: "0900",
              },
              close: {
                day: 1,
                time: "2000",
              },
            },
            {
              open: {
                day: 2,
                time: "0900",
              },
              close: {
                day: 2,
                time: "2000",
              },
            },
            {
              open: {
                day: 3,
                time: "0900",
              },
              close: {
                day: 3,
                time: "2000",
              },
            },
            {
              open: {
                day: 4,
                time: "0900",
              },
              close: {
                day: 4,
                time: "2000",
              },
            },
            {
              open: {
                day: 5,
                time: "0900",
              },
              close: {
                day: 5,
                time: "2000",
              },
            },
            {
              open: {
                day: 6,
                time: "0900",
              },
              close: {
                day: 6,
                time: "2000",
              },
            },
            {
              open: {
                day: 7,
                time: "0900",
              },
              close: {
                day: 7,
                time: "2000",
              },
            },
          ],
          weekday_text: [
            "Monday: 09:00 - 20:00",
            "Tuesday: 09:00 - 20:00",
            "Wednesday: 09:00 - 20:00",
            "Thursday: 09:00 - 20:00",
            "Friday: 09:00 - 20:00",
            "Saturday: 09:00 - 20:00",
            "Sunday: 09:00 - 20:00",
          ],
        },
        category: {
          name: "attraction",
          localized_name: "Attraction",
        },
        subcategory: [
          {
            name: "attractions",
            localized_name: "Attractions",
          },
          {
            name: "outdoor_activities",
            localized_name: "Outdoor Activities",
          },
          {
            name: "sightseeing_tours",
            localized_name: "Tours",
          },
          {
            name: "activities",
            localized_name: "Activities",
          },
          {
            name: "other",
            localized_name: "Other",
          },
        ],
        groups: [
          {
            name: "Tours",
            localized_name: "Tours",
            categories: [
              {
                name: "Private Tours",
                localized_name: "Private Tours",
              },
              {
                name: "Cultural Tours",
                localized_name: "Cultural Tours",
              },
              {
                name: "Eco Tours",
                localized_name: "Eco Tours",
              },
              {
                name: "City Tours",
                localized_name: "City Tours",
              },
              {
                name: "Day Trips",
                localized_name: "Day Trips",
              },
            ],
          },
          {
            name: "Other",
            localized_name: "Other",
            categories: [
              {
                name: "",
                localized_name: "",
              },
            ],
          },
          {
            name: "Outdoor Activities",
            localized_name: "Outdoor Activities",
            categories: [
              {
                name: "Eco Tours",
                localized_name: "Eco Tours",
              },
            ],
          },
        ],
        neighborhood_info: [],
        trip_types: [
          {
            name: "business",
            localized_name: "Business",
            value: "0",
          },
          {
            name: "couples",
            localized_name: "Couples",
            value: "3",
          },
          {
            name: "solo",
            localized_name: "Solo travel",
            value: "4",
          },
          {
            name: "family",
            localized_name: "Family",
            value: "15",
          },
          {
            name: "friends",
            localized_name: "Friends getaway",
            value: "19",
          },
        ],
        awards: [],
      };
      this.rating = response.rating;
      this.num_reviews = response.num_reviews;
    },
    getCompanyData() {
      /*    const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "same-origin",
            }
  
            fetch(`${process.env.VUE_APP_API_URL}/tripadvisor/reviews`, options)
                .then(response => response.json())
                .then(response => {
                    this.Reviews = response.data
                })
                .catch(err => console.error(err)); */
      const response = {
        data: [
          {
            id: 957847879,
            lang: "en",
            location_id: 26717876,
            published_date: "2024-07-03T13:23:56Z",
            rating: 5,
            helpful_votes: 1,
            rating_image_url:
              "https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/s5.0-66827-5.svg",
            url: "https://www.tripadvisor.com/ShowUserReviews-g189158-d26717876-r957847879-Reviews-Guiders_pt_Shared_tours_activities-Lisbon_Lisbon_District_Central_Portugal.html?m=66827#review957847879",
            text: "We had a great tuk-tuk tour with Marco Sousa. He took us to places where we could never find ourselves. He is very knowledgeable about the city of Lisbon and the history of Portugal.  I highly recommend this.",
            title: "Best Tuk-Tuk tour in Lisboa",
            trip_type: "Family",
            travel_date: "2024-07-31",
            user: {
              username: "francislo",
              user_location: {
                id: "652092",
                name: "Matosinhos, Porto District, Northern Portugal",
              },
              avatar: {
                thumbnail:
                  "https://media-cdn.tripadvisor.com/media/photo-t/15/4d/88/70/francislo.jpg",
                small:
                  "https://media-cdn.tripadvisor.com/media/photo-l/15/4d/88/70/francislo.jpg",
                medium:
                  "https://media-cdn.tripadvisor.com/media/photo-f/15/4d/88/70/francislo.jpg",
                large:
                  "https://media-cdn.tripadvisor.com/media/photo-p/15/4d/88/70/francislo.jpg",
                original:
                  "https://media-cdn.tripadvisor.com/media/photo-o/15/4d/88/70/francislo.jpg",
              },
            },
            subratings: {},
          },
          {
            id: 951924903,
            lang: "en",
            location_id: 26717876,
            published_date: "2024-05-22T13:45:35Z",
            rating: 5,
            helpful_votes: 0,
            rating_image_url:
              "https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/s5.0-66827-5.svg",
            url: "https://www.tripadvisor.com/ShowUserReviews-g189158-d26717876-r951924903-Reviews-Guiders_pt_Shared_tours_activities-Lisbon_Lisbon_District_Central_Portugal.html?m=66827#review951924903",
            text: "My wife and I had a wonderful Tuk Tuk ride driven by Marco. He is friendly, helpful, professional and very knowledgeable of the history of Portugal. \n\nHis commentary of the places we visited makes the ride so much more interesting and enjoyable. He gave us plenty of time at each stop and took some great pictures for us. \n\nIt was a wonderful 2+ hours of time spent with Marco. We highly recommend riding with him.",
            title: "Wonderful Tuk Tuk Ride with Marco",
            trip_type: "Couples",
            travel_date: "2024-05-31",
            user: {
              username: "Navigate62308262256",
              user_location: {
                id: "null",
              },
              avatar: {
                thumbnail:
                  "https://media-cdn.tripadvisor.com/media/photo-t/1a/f6/e7/99/default-avatar-2020-57.jpg",
                small:
                  "https://media-cdn.tripadvisor.com/media/photo-l/1a/f6/e7/99/default-avatar-2020-57.jpg",
                medium:
                  "https://media-cdn.tripadvisor.com/media/photo-f/1a/f6/e7/99/default-avatar-2020-57.jpg",
                large:
                  "https://media-cdn.tripadvisor.com/media/photo-p/1a/f6/e7/99/default-avatar-2020-57.jpg",
                original:
                  "https://media-cdn.tripadvisor.com/media/photo-o/1a/f6/e7/99/default-avatar-2020-57.jpg",
              },
            },
            subratings: {},
          },
          {
            id: 944844971,
            lang: "en",
            location_id: 26717876,
            published_date: "2024-04-01T15:49:18Z",
            rating: 5,
            helpful_votes: 0,
            rating_image_url:
              "https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/s5.0-66827-5.svg",
            url: "https://www.tripadvisor.com/ShowUserReviews-g189158-d26717876-r944844971-Reviews-Guiders_pt_Shared_tours_activities-Lisbon_Lisbon_District_Central_Portugal.html?m=66827#review944844971",
            text: "Really nice experience, is definitely a must for everybody who comes to Lisbon! So much history to learn about this amazing city! We love it!!",
            title: "Tuk tuk tour",
            trip_type: "Friends getaway",
            travel_date: "2024-03-31",
            user: {
              username: "edgaraV5153NI",
              user_location: {
                id: "null",
              },
              avatar: {
                thumbnail:
                  "https://media-cdn.tripadvisor.com/media/photo-t/1a/f6/ed/00/default-avatar-2020-4.jpg",
                small:
                  "https://media-cdn.tripadvisor.com/media/photo-l/1a/f6/ed/00/default-avatar-2020-4.jpg",
                medium:
                  "https://media-cdn.tripadvisor.com/media/photo-f/1a/f6/ed/00/default-avatar-2020-4.jpg",
                large:
                  "https://media-cdn.tripadvisor.com/media/photo-p/1a/f6/ed/00/default-avatar-2020-4.jpg",
                original:
                  "https://media-cdn.tripadvisor.com/media/photo-o/1a/f6/ed/00/default-avatar-2020-4.jpg",
              },
            },
            subratings: {},
          },
          {
            id: 937572250,
            lang: "en",
            location_id: 26717876,
            published_date: "2024-02-09T16:26:41Z",
            rating: 5,
            helpful_votes: 1,
            rating_image_url:
              "https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/s5.0-66827-5.svg",
            url: "https://www.tripadvisor.com/ShowUserReviews-g189158-d26717876-r937572250-Reviews-Guiders_pt_Shared_tours_activities-Lisbon_Lisbon_District_Central_Portugal.html?m=66827#review937572250",
            text: "What an amazing experience we had ,,, the guides were very patient social and friendly ,,Our tour guide Carlos made it extra amazing ,, it was really funny,, i super recommend",
            title: "Seven Hills Tuk Tuk",
            trip_type: "Friends getaway",
            travel_date: "2023-12-31",
            user: {
              username: "kennethmG3480HB",
              user_location: {
                id: "null",
              },
              avatar: {
                thumbnail:
                  "https://media-cdn.tripadvisor.com/media/photo-t/1a/f6/f2/eb/default-avatar-2020-27.jpg",
                small:
                  "https://media-cdn.tripadvisor.com/media/photo-l/1a/f6/f2/eb/default-avatar-2020-27.jpg",
                medium:
                  "https://media-cdn.tripadvisor.com/media/photo-f/1a/f6/f2/eb/default-avatar-2020-27.jpg",
                large:
                  "https://media-cdn.tripadvisor.com/media/photo-p/1a/f6/f2/eb/default-avatar-2020-27.jpg",
                original:
                  "https://media-cdn.tripadvisor.com/media/photo-o/1a/f6/f2/eb/default-avatar-2020-27.jpg",
              },
            },
            subratings: {},
          },
          {
            id: 937569931,
            lang: "en",
            location_id: 26717876,
            published_date: "2024-02-09T15:59:36Z",
            rating: 5,
            helpful_votes: 0,
            rating_image_url:
              "https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/s5.0-66827-5.svg",
            url: "https://www.tripadvisor.com/ShowUserReviews-g189158-d26717876-r937569931-Reviews-Guiders_pt_Shared_tours_activities-Lisbon_Lisbon_District_Central_Portugal.html?m=66827#review937569931",
            text: "I had one of the best holidays thanks to you, I'm grateful to the guiders, I hope to be able to connect with your services very soon. I recommend it to anyone who wants to get to know Portugal better",
            title: "sintra tour",
            trip_type: "Solo travel",
            travel_date: "2023-12-31",
            user: {
              username: "bacelam",
              user_location: {
                id: "null",
              },
              avatar: {
                thumbnail:
                  "https://media-cdn.tripadvisor.com/media/photo-t/1a/f6/e2/11/default-avatar-2020-41.jpg",
                small:
                  "https://media-cdn.tripadvisor.com/media/photo-l/1a/f6/e2/11/default-avatar-2020-41.jpg",
                medium:
                  "https://media-cdn.tripadvisor.com/media/photo-f/1a/f6/e2/11/default-avatar-2020-41.jpg",
                large:
                  "https://media-cdn.tripadvisor.com/media/photo-p/1a/f6/e2/11/default-avatar-2020-41.jpg",
                original:
                  "https://media-cdn.tripadvisor.com/media/photo-o/1a/f6/e2/11/default-avatar-2020-41.jpg",
              },
            },
            subratings: {},
          },
        ],
      };
      this.Reviews = response.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-outlined {
  background-color: transparent;
  border: 1px solid #0e3d4d;
  border-radius: 50px;
  width: -moz-max-content;
  width: max-content;
  padding: 1rem;
  height: 3rem;
  display: flex;
  align-items: center;

  .icon {
    color: #0e3d4d;
    height: 1rem;
    width: auto;
  }
  &:hover {
    background-color: #0e3d4d;
    .icon {
      color: white;
    }
  }
}

.scroll {
  position: relative;
  height: 20rem;
  background-color: transparent;
  overflow: hidden;
  z-index: 1;
  margin-left: -20px;
  padding-left: 20px;
  margin-right: -20px;
}

.about-image {
  img {
    height: 20rem;
    //margin-right: 0.5rem;
    //border-radius: 20px;
    min-width: 400px;
  }
}
.title,
.title-md {
    font-family: "Playfair Display", serif; 
  color: #0e3d4d;
  font-weight: 500;
}

.subtitle-sm {
  color: rgb(101, 142, 155);
}

.m-scroll {
  overflow: hidden;
  white-space: nowrap;
  animation: scrollText 40s infinite linear;
  margin: 0;
  font-size: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  height: 20rem;

  span {
    display: inline-block;
    width: fit-content;
    margin: 0 0.5rem;
    padding: 0;
    color: white;
  }
}

@keyframes scrollText {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-90%);
  }
}

.tag--outlined {
  background-color: transparent;
  border: 1px solid #0e3d4d;
  color: #0e3d4d;
}

.rating-container {
  width: fit-content;
}

.outlined-button {
  background-color: #b5f2dc;
  font-weight: 600;
  border: none !important;
  padding: 0.7rem 18px;
  color: rgba(14, 61, 77, 0.9098039216) !important;
  border-radius: 46px;
  font-size: 0.88rem;
  display: flex;
}

.testimony-title {
  font-size: 1.1rem;
}

.testimonials {
  margin: -20px;
  padding: 20px;

  .subtitle {
    //color: #2b3f6ccf;
    font-family: "Inter", sans-serif;
    font-size: 0.85rem;
    font-weight: 300;
  }

  .title {
    font-size: 1.75rem;
  }
}

::v-deep {
  .rate .rate-item.set-on .icon {
    color: #fcd581;
  }
}

.media--container {
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin: 0 -20px;
  padding: 0 12px;

  .media-content {
    flex: 0 0 auto;
    background-color: white;
    &:hover {
      background-color: #08401808;
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.link {
  color: #3ddc97;
}

.media-content {
  padding: 0px 18px;
  border-radius: 20px;
    font-family: "Playfair Display", serif; 
  color: #0e3d4d;
  font-weight: 500;
  margin: 12px;

  p {
    margin-bottom: 0;
  }
}


.ta_logo {
  height: 2.75rem;
}

.blue-container {
  border-radius: 6px;
  padding: 8px 16px;
  background-color: #0e3d4d;
  color: #fff;
  cursor: pointer;
  width: fit-content;
}

.review-container,
.rating-container {
  border-radius: 12px;
  align-items: center;
}

.rating-img {
  height: 1rem;
  width: auto;
  margin-left: -11px;
}

.ta-avatar-img {
  border-radius: 50px;
}

@media only screen and (max-width: 39em) {
  .media-content {
    width: 66vw;
  }
}

@media only screen and (min-width: 40em) {
  .media-content {
    width: 54vw;
  }
}

/* Medium devices such as tablets (768px and up) */
@media only screen and (min-width: 48em) {
  .media-content {
    width: 40vw;
  }
}

/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 64em) {
  .media-content {
    width: 43vw;
  }
}

/* Largest devices such as desktops (1280px and up) */
@media only screen and (min-width: 80em) {
  .media-content {
    width: 22vw;
  }
}
@media (max-width: 506px) {
  .display-flex-space-btw {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 768px) {
    .order-1-mobile {
      order: 1;
    }
    .order-2-mobile {
      order: 2;
    }
    .testimonials{
        display: flex;
        flex-direction: column;
      }
  }

::v-deep {
  .carousel-arrow .icon {
    background: #446df6;
    color: #fff;
  }

  .rating-date {
    font-size: 0.85rem;
    color: #0e3d4d;
  }

  .carousel-list.has-shadow {
    box-shadow: none;
  }

  .carousel-list .carousel-slides .carousel-slide:not(:first-of-type) {
    margin-left: 1.25rem;
  }
}
</style>
